export const delay = (timeout) => {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
};

export const freeFarmeSpeed = {
  bachi: 23148148150000,
  taiko: 34722222220,
};
